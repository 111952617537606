module.exports = {
	general: {
		contactSubsidiary: 'Bitte kontaktieren Sie eine ',
		contactSubsidiaryLinkText: 'Santander-Filiale in Ihrer Nähe',
		contactSubsidiaryLink:
			'https://service.santander.de/webapps/apps/filialen?solo=1',
		creditAmount: 'Kreditbetrag',
		moreInfo: 'Mehr Informationen'
	},
	progressBar: {
		step1: 'Ihre Angaben',
		step2: 'Ihre Person',
		step3: 'Antrag senden',
		subline: {
			letsGo:
				"<strong>Los geht's:</strong> Der Antrag dauert nur {minutes} Minuten."
		}
	},
	personalData: 'Persönliche Daten',
	address: 'Anschrift',
	contactDetails: 'Kontaktdetails',
	occupation: 'Beschäftigungsverhältnis',
	appointmentButton: {
		title: 'Haben Sie Fragen?',
		buttonText: 'Kontakt',
		phone: {
			title: 'Rufen Sie uns an'
		},
		appointment: {
			title: 'Lassen Sie sich beraten',
			linkText: 'Terminvereinbarung'
		}
	},
	logoExitPopup: {
		title: 'Bitte gehen Sie noch nicht!',
		description: 'Mit dem Klick auf den Button werden Sie auf die Santander Startseite geleitet. Bereits eingegebene Informationen gehen dadurch verloren.',
		button: 'Zur Startseite'
	},
	exitPopup: {
		subhead: 'Gehen Sie noch nicht!',
		continueButton: 'Fortfahren'
	},
	download: {
		insuranceContract: {
			application: 'Versicherungsvertrag herunterladen',
			pdftitle: 'Versicherungsvertrag.pdf'
		}
	},
	pages: {
		error: {
			technical: {
				title: 'Leider ist ein technischer Fehler aufgetreten.',
				subtitle: 'Bitte rufen Sie uns an, damit wir Ihren Kreditwunsch schnell erfüllen können.<br/>Unter <a class="link primary" href="tel:{phoneNumberLink}">{phoneNumber}</a> sind wir montags bis freitags in der Zeit von 8:00 - 20:00 Uhr für Sie da.'
			}
		},
		thankYou: {
			sanctions: {
				title: 'Vielen Dank für Ihre Anfrage.',
				description: 'Aufgrund der aktuellen Sanktionen der Europäischen Union ist es uns gegenwärtig leider nicht möglich, für Sie online ein Depot zu eröffnen. Hiervon bestehen Ausnahmen, sofern Sie nachweisen können, dass Sie über eine aktuell gültige Aufenthaltserlaubnis und/oder eine weitere Staatsbürgerschaft von einem Land der Europäischen Union verfügen. Falls dies zutrifft möchten wir Sie bitten, sich bezüglich einer Depoteröffnung an eine Filiale in Ihrer Nähe oder die Online-Filiale erreichbar unter <a class="link primary" href="tel:+4921619060187">+49 (0) 2161 9060-187</a> oder <a class="link primary" href="mailto:expertenberatung@santander.de">expertenberatung@santander.de</a> zu wenden.'
			},
			pep: {
				title: 'Vielen Dank für Ihre Anfrage! Die Bearbeitung Ihres Antrags ist leider online nicht möglich.',
				subtitle: ''
			}
		},
		login: {
			title: 'Schön, dass Sie wieder da sind!',
			subtitle:
				'Bitte melden Sie sich mit Ihrer bereits angegebenen E-Mail-Adresse an.',
			backToRegistration: '< Zur Registrierung',
			unauthorized: {
				message: 'Ihre vorherige Sitzung ist abgelaufen, deshalb wurden Sie zum Login weitergeleitet.'
			}
		},
		name: {
			title: 'Persönliche Daten',
			subtitle:
				'Bitte geben Sie alle in Ihrem Ausweisdokument geführten Namen an.',
			alreadyKnown: 'Antrag fortführen?',
			loginHere: 'Hier weitermachen',
			neededUtilsAndDocuments: {
				title: 'Bitte halten Sie folgendes bereit, um Zeit zu sparen:',
				items: {
					passport: 'Ausweisdokument',
					mobileNumber: 'Handynummer',
					iban: 'IBAN Ihres Girokontos',
					camera: 'PC- oder Handykamera'
				}
			}
		},
		birthdate: {
			title: 'Geburtsdatum',
			subtitle: 'Für die Kontoeröffnung müssen Sie volljährig sein.',
			validation: {
				notOver18:
					'Bitte beachten Sie, dass Sie dieses Konto nur eröffnen können, wenn Sie volljährig sind.<br/>'
			}
		},
		email: {
			title: 'E-Mail-Adresse',
			subtitle:
				'Ihre E-Mail-Adresse wird benötigt, damit Sie Ihr BestGiro digital eröffnen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.',
			resendEmail: {
				link: 'Link noch einmal senden',
				success: 'Link gesendet'
			}
		},
		emailSent: {
			pretitle: 'Wir haben Ihnen eine <span class="no-wrap">E-Mail</span> an <strong>{email}</strong> geschickt.',
			title: 'Bestätigen Sie Ihre <span class="no-wrap">E-Mail</span>-Adresse.',
			subtitle: 'Bitte klicken Sie innerhalb von <strong>20 Minuten</strong> auf den Link, um Ihre E-Mail-Adresse zu bestätigen. Prüfen Sie ggf. den Spam-Ordner.'
		},
		emailSentToCustomer: {
			title: 'Die E-Mail an den Kunden wurde erfolgreich geschickt'
		},
		emailConfirmed: {
			title: 'Sie haben Ihre E-Mail-Adresse erfolgreich bestätigt.',
			subtitle: 'Bitte bestätigen Sie im nächsten Schritt Ihre Mobilfunknummer.'
		},
		emailNotConfirmed: {
			title: 'Ihre E-Mail-Adresse konnte nicht bestätigt werden.',
			subtitle: 'Bitte versuchen Sie es noch einmal.',
			submit: 'Nochmals versuchen'
		},
		mobileNumber: {
			title: 'Mobilfunknummer',
			subtitle:
				'Zur Verifizierung Ihrer Daten senden wir Ihnen im nächsten Schritt eine SMS mit einem Code zu.',
			subtitle2dl:
				'Die Mobilfunknummer muss unterschiedlich zu der Mobilfunkummer des ersten Kreditnehmers sein. Diese wird für die spätere digitale Vertragsunterschrift benötigt.'
		},
		mobileNumberConfirmation: {
			title: 'Bitte bestätigen Sie Ihre Mobilfunknummer.',
			subtitle: 'Wir haben Ihnen eine SMS mit einem Bestätigungs-Code an Ihre Mobilfunknummer ({number}) geschickt.',
			subtitleNoNumber:
				'Wir haben Ihnen eine SMS mit einem Bestätigungs-Code an Ihre hinterlegte Mobilfunknummer geschickt.',
			subtitleEncryptedNumber:
				'Wir haben Ihnen eine SMS mit einem Bestätigungs-Code an Ihre hinterlegte Mobilfunknummer ({number}) geschickt.'
		},
		nationality: {
			title: 'Staatsangehörigkeit'
		},
		address: {
			title: 'Adresse',
			subtitle: 'Sie benötigen eine deutsche Wohnadresse. Bitte geben Sie diese so ein, wie sie im Ausweisdokument angegeben ist.'
		},
		previousAddress: {
			title: 'Vorherige Adresse',
			subtitle: 'Ihre Angabe wird für die SCHUFA-Auskunft benötigt.'
		},
		maritalStatus: {
			title: 'Familienstand',
			subtitle: 'Diese Angabe wird für die Bonitätsprüfung benötigt.',
			children: 'Geben Sie an, wie viele Kinder in Ihrem Haushalt leben.'
		},
		occupation: {
			title: 'Beschäftigungsverhältnis',
			sinceWhen: 'Seit wann?',
			limited: 'Ist das Arbeitsverhältnis befristet?',
			endOfLimitation: 'Wann endet das Arbeitsverhältnis?'
		},
		bankAccount: {
			title: 'Bankverbindung Girokonto'
		},
		financialStatus: {
			title: 'Angaben zu Einnahmen und Ausgaben',
			subtitle: 'Bitte geben Sie Ihre monatlichen Einkünfte und Wohnkosten an.'
		},
		financialStatusDAC: {
			title: 'Angaben zu Einnahmen und Ausgaben', // 'Wie möchten Sie die Angaben zu Ihren Einnahmen und Ausgaben machen?',
			titleReminder: 'Nachweise zu Einnahmen und Ausgaben',
			subtitleReminder:
				'Wählen Sie den <strong>automatischen</strong> Weg und Ihre <strong>Nachweisunterlagen</strong> werden <strong>sofort geprüft</strong>. Sie sparen Zeit und beschleunigen Ihre Auszahlung.',
			automatic: {
				box: {
					title: 'Automatisch',
					description:
						'Lassen Sie Ihre Einnahmen und Ausgaben mit Hilfe des digitalen Kontoblicks schnell, einfach und sicher prüfen.',
					descriptionReminder:
						'Lassen Sie Ihre Einnahmen und Ausgaben mit Hilfe des digitalen Kontoblicks schnell, einfach und sicher prüfen.',
					tooltip:
						'Für die Erstellung Ihres individuellen Angebotes werden einmalig die Umsatzdaten auf Ihrem Gehaltskonto nach Eingabe Ihrer Online Banking Zugangsdaten abgerufen. Die Zugangsdaten werden ausschließlich für diesen einmaligen Kontoblick zum Zweck der Bonitätsprüfung genutzt und nicht gespeichert.<br/><span class="list">• Blitzschnelle Auszahlung<br/>• Sofortige Kreditentscheidung<span>'
				},
				description: {
					title: 'Ihr Konto für die automatische Erfassung:',
					description:
						'Über eine sichere Schnittstelle erfolgt die automatische Auslesung der Daten von dem Konto von dem auch Ihre monatlichen Raten abgebucht werden.'
				}
			},
			manually: {
				box: {
					title: 'Manuell',
					description:
						'Geben Sie Ihre Einnahmen und Ausgaben hier manuell ein. Im späteren Verlauf können Sie dann die Nachweisdokumente hochladen oder diese per Post zusenden.',
					descriptionReminder:
						'Im nächsten Schritt können Sie Ihre Dokumente (z.B. Gehaltsabrechnung, Kontoauszüge etc.) hochladen. Diese werden manuell von unseren Mitarbeitern geprüft.'
				},
				description: {
					title: 'Monatliche Einnahmen und Ausgaben'
				}
			}
		},
		newIbanDAC: {
			title: 'Wir konnten kein Gehalt unter der angegebenen IBAN auslesen. Wie möchten Sie fortfahren?',
			automatic: {
				box: {
					title: 'Neue IBAN des Gehaltskontos eingeben'
				},
				description: {
					title: 'Bankverbindung Gehaltskonto',
					description: 'Von diesem Konto erfolgt auch die Abbuchung Ihrer monatlichen Rate.'
				}
			},
			manually: {
				box: {
					title: 'Manuell die Einnahmen und Ausgaben eingeben'
				}
			}
		},
		digitalAccountCheck: {
			title: 'Anmeldung in Ihrem Online Banking', // 'Bitte melden Sie sich in Ihrem Online Banking an',
			subtitle:
				'Loggen Sie sich mit Ihren Zugangsdaten des Online Banking Ihrer Hausbank ein. Die Tink Germany GmbH wird innerhalb von ein paar Sekunden Ihre Kontoumsätze automatisch analysieren und Sie erhalten sofort eine Kreditentscheidung.',
			privacy: 'ISO27001 Zertifizierung',
			ssl: 'Sichere SSL-Verschlüsselung',
			tuev:
				' Unser starker und sicherer Partner Tink Germany GmbH steht für höchste Datensicherheit und ist mit dem TÜV-Siegel <b>geprüfter Datenschutz</b> zertifiziert.'
		},
		digitalAccountCheckSuccess: {
			title: 'Der digitale Kontoblick war erfolgreich!',
			subtitle:
				'Wir konnten alle erforderlichen Nachweisinformationen ermitteln. Jetzt fehlen nur noch wenige Schritte zum Abschluss Ihrer Anfrage.'
		},
		digitalAccountCheckFailure: {
			title: 'Wir können die Daten leider nicht auslesen.',
			subtitle: 'Was sind Ihre aktuellen Einnahmen und Ausgaben?',
			subtitleReminder: 'Fahren Sie mit dem Dokumenten-Upload fort.'
		},
		externalCredit: {
			title: 'Weitere Kredite ablösen',
			subtitle:
				'Falls Sie noch weitere Kredite bei uns oder einer anderen Bank haben, legen wir diese gerne für Sie zusammen. So können Sie ggf. Geld sparen.',
			tooltip:
				'Mit einer Umschuldung bestehender Kredite mit dem BestCredit profitieren Sie gleich doppelt: von flexiblen Laufzeiten und fairen Zinsen. Ihre monatliche Belastung sinkt, Sie können mehr Kapital in die Tilgungen investieren und sind eher schuldenfrei.'
		},
		taxNumber: {
			title: 'Steueransässigkeit außerhalb Deutschlands',
			tinTitle: 'Wie lautet Ihre deutsche Steuer-ID?',
			tinSubtitle: 'Sie finden Ihre 11-stellige Steuer-ID beispielsweise auf Ihrem Einkommensteuerbescheid. Sie können das Feld frei lassen und wir ermitteln die Steuer-ID für Sie.',
			yesOthers: 'Ja, andere',
			yesUS: 'Ja, USA',
			validationErrorTin: 'Bitte geben Sie eine gültige Steuer-ID ein.'
		},
		noUs: {
			title:
				'Eine Depoteröffnung für Kunden mit US-amerikanischer Steuerpflicht ist online leider nicht möglich.'
		},
		dispo: {
			title: 'Ihr Dispositionskredit',
			subtitle:
				'Ihre finanzielle Flexibilität durch die eingeräumte Kontoüberziehung <sup>1)</sup> beträgt:',
			infoText:
				'Wünschen Sie einen anderen Dispo? Nach erfolgter Kontoeröffnung und Gehalts– oder Renteneingang haben Sie jederzeit die Möglichkeit Ihren Verfügungsrahmen <sup>1)</sup> entsprechend Ihren Bedürfnissen anzupassen.',
			footNote: '1) Bonität vorausgesetzt',
			tooltipTitle: 'Dispositionskredit',
			tooltipText:
				'Auch als eingeräumte Kontoüberziehung bezeichnet, gibt Ihnen die finanzielle Flexibilität auch bei kurzfristigen wirtschaftlichen Engpässen, jederzeit liquide zu sein. Wann Sie den in Anspruch genommenen Dispositionskredit zurückzahlen, entscheiden Sie dabei selbst.'
		},
		giroData: {
			title: 'Angaben für Ihr BestGiro Konto'
		},
		insurance: {
			title: 'Wünschen Sie eine Absicherung?',
			protect: {
				title: 'Einkommensausfall absichern',
				subtitle: 'Wir empfehlen Ihnen den Abschluss der Santander Protect, um Ihr monatliches Einkommen gegen eine Vielzahl von Risiken abzusichern.',
				hint: '* Die Laufzeit der Santander Protect beträgt 60 Monate und verlängert sich automatisch um ein Jahr, sollte dies nicht von Ihnen gekündigt werden.',
				body: {
					description: 'Auf Basis der von Ihnen zuvor gemachten Gehaltsangaben, haben wir für verschiedene Szenarien Ihre Einkommenslücke berechnet. Die Absicherungshöhe ist die monatliche Leistung oder Einmalzahlung, die Sie im Falle eines Einkommensausfalles erhalten, maximal 1.000€.',
					runtime: {
						label: 'Laufzeit'
					},
					coverage: {
						label: 'Absicherungshöhe'
					},
					selectTitle: 'Protect Tarif wählen',
					table: {
						spacer: {
							monthlyPayment: 'Monatliche Auszahlung:',
							oneTimePayment: 'Einmalzahlung:'
						},
						row1: {
							title: 'Unbezahlte Auszeit, Temporäre Arbeitszeitverkürzung, Elternzeit, Pflegezeit',
							popup: {
								headline1: 'Unbezahlte Auszeit, Temporäre Arbeitszeitverkürzung, Pflegezeit',
								content1: '<p>Sie wünschen sich eine längere Auszeit vom Beruf, zum Beispiel für ein Sabbatical, zur Pflege eines Angehörigen oder eine persönliche Weiterbildung? Oder möchten Sie aus persönlichen Gründen Ihre Arbeitszeit verkürzen – zum Beispiel für Ihre Familie oder Ihre Gesundheit?</p><ul><li>Beispiel: Bei einem Nettoeinkommen von 2.000€ beträgt die Einkommenslücke während einer temporären Arbeitszeitverkürzung ca. 1.000€ pro Monat</li><li>Sie erhalten bis zu 6 Monate 100% der vereinbarten monatlichen Leistung</li><li>Unbezahlte Abwesenheit von mindestens 4 Wochen und zeitlich begrenzte Arbeitszeitverkürzungen um mindestens 50% sind versichert.</li></ul>',
								headline2: 'Elternzeit',
								content2: '<p>Nehmen Sie sich Zeit für Ihre Familie – ohne Sorgen um Ihr Einkommen</p><ul><li>Beispiel: Das Basiselterngeld beträgt in der Regel 65-67% des Nettoeinkommens. Bei einem Nettoeinkommen von 2000 € beträgt der Einkommensausfall während der Elternzeit ca. 660 € pro Monat.</li><li>Sie erhalten bis zu 6 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Adoptionen sind mitversichert.</li></ul>'
							}
						},
						row2: {
							title: 'Kurzarbeit, Arbeitslosigkeit',
							popup: {
								headline1: 'Kurzarbeit',
								content1: '<p>Auch in schwierigen Zeiten bleibt Ihr Einkommen stabil</p><ul><li>Beispiel: Bei einem Nettoeinkommen von 2000 € beträgt die Einkommenslücke während der konjunkturellen Kurzarbeit ca. 400 € pro Monat.</li><li>Sie erhalten bis zu 12 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Auch bei befristeten Arbeitsverträgen.</li></ul>',
								headline2: 'Arbeitslosigkeit',
								content2: '<p>Fokussieren Sie sich auf Ihren nächsten Job – und nicht auf Ihre finanziellen Sorgen</p><ul><li>Planbares Einkommen trotz Arbeitslosigkeit: Bei einem Nettoeinkommen von 2000 € beträgt die Einkommenslücke während der Arbeitslosigkeit ca. 800 € pro Monat.</li><li>Sie erhalten bis zu 18 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Arbeitnehmer und Angestellte im öffentlichen Dienst sind mitversichert.</li></ul>'
							}
						},
						row3: {
							title: 'Arbeitsunfähigkeit',
							popup: {
								headline1: 'Arbeitsunfähigkeit',
								content1: '<p>Konzentrieren Sie sich auf Ihre Genesung - ohne Geldsorgen</p><ul><li>Beispiel: Bei einem Nettoeinkommen von 2000 € beträgt der Einkommensausfall während der Arbeitsunfähigkeit ca. 400 € pro Monat.</li><li>Sie erhalten bis zu 18 Monate 100% der vereinbarten monatlichen Leistung.</li><li>Ohne Gesundheitsfragen.</li><li>Psychische Erkrankungen sind mitversichert.</li></ul>'
							}
						},
						row4: {
							title: 'Lebensereignisse (z.B. Heirat, Geburt)',
							popup: {
								headline1: 'Lebensereignisse',
								content1: '<p>Zu bestimmten Lebensereignissen – zur Geburt oder Adoption Ihres Kindes, zur Heirat oder Scheidung – erhalten Sie eine Einmalzahlung in Höhe Ihrer gewählten Leistung.</p>'
							}
						},
						standard: {
							title: 'Standard'
						},
						premium: {
							title: 'Premium',
							higlightLabel: 'Unsere Empfehlung'
						},
						basic: {
							title: 'Basis'
						},
						noProtect: 'Ich will meinen Einkommensausfall nicht absichern'
					}
				}
			}
		},
		termsAndConditions: {
			title: 'Jetzt nur noch das Rechtliche',
			titleShort: 'Rechtliches'
		},
		thankYou2: {
			title: 'Ihre Anfrage ist erfolgreich eingegangen.',
			description:
				'Wir melden uns <strong>innerhalb von 48 Std.</strong> bei Ihnen.',
			description2:
				'Unser Team prüft gerade Ihren Antrag und <strong>kontaktiert Sie heute</strong> im Laufe des Tages.',
			yourNumber: 'Ihre Antragsnummer',
			hotlineDescription11:
				'Bei Fragen stehen wir Ihnen unter der Tel. ',
			hotlineDescription12:
				' zur Verfügung.',
			hotlineDescription21:
				'Wenn Sie die Entscheidung zu Ihrem Antrag sofort erfahren möchten, rufen Sie einfach direkt unseren Kundenberater unter ',
			hotlineDescription22:
				' an.',
			faqDescription:
				'Nutzen Sie die Wartezeit und informieren Sie sich in unserem Ratgeber rund um die <a class="link primary" href="https://www.santander.de/ratgeber" target="_blank" rel="noopener noreferer">Themen Kaufen, Finanzieren und Finanzplanung</a> im Alltag.'
		},
		thankYouSchufaFailed: {
			title: 'Vielen Dank für Ihre Anfrage.',
			description: 'Nach Prüfung müssen wir Ihnen leider mitteilen, dass wir Ihrem Antrag zur Zeit nicht entsprechen können.<br/><br/>Wir bitten um Ihr Verständnis.'
		},
		upsell: {
			title: 'Da ist noch mehr drin - Ihr individuelles Kreditangebot!',
			subtitle: 'Wir haben Ihre Daten geprüft und können Ihnen einen max. Kredit von {amount} € anbieten.',
			offer: {
				title: 'Unser Kreditangebot für Sie:',
				monthlyRateInfo:
					'Es handelt sich um eine vorläufige Kalkulation, die geringfügig zur finalen Kalkulation abweichen kann.'
			},
			edit: {
				title: 'Gleich konfigurieren:',
				subtitle: 'Ein Kredit, der perfekt zu Ihnen passt!'
			}
		},
		upsellDownsell: {
			offer: 'Unser Kreditangebot für Sie:',
			editRightAway: 'Gleich konfigurieren:',
			toUpsellDownsell: 'Zu dem individuellen Angebot',
			monthlyRateInfo:
				'Es handelt sich um eine vorläufige Kalkulation, die geringfügig zur finalen Kalkulation abweichen kann.',
			upsell: {
				wantMore: 'Darf es etwas mehr sein?',
				biggerPlans: 'Ihre Pläne sind doch größer?',
				howAboutThisOffer:
					'Wie wäre es mit diesem Angebot bei fast gleicher monatlicher Rate?',
				creditThatPerfectlyFits: 'Ein Kredit, der perfekt zu Ihnen passt!',
				monthlyRateTooHigh: 'Monatliche Rate zu hoch!'
			},
			success: {
				header: {
					title: 'Da ist noch mehr drin - Ihr individuelles Kreditangebot!',
					//TODO: tbe: 28.07.21 replace xxxx
					subtitlePrefix:
						'Wir haben Ihre Daten geprüft und können Ihnen einen max. Kredit von',
					subtitleSuffix: ' € anbieten.'
				},
				yourRequest: {
					label: 'Ihre Anfrage'
				},
				suggestion: {
					label: 'Option'
				}
			},
			fallback: {
				header: {
					title: 'Ihren Kreditwunsch können wir zwar nicht ganz erfüllen',
					subtitle: 'Aber fast - denn wir haben da eine gute Idee!'
				}
			}
		},
		identification: {
			title: 'Identifizierung und Unterschrift',
			titleShort: 'Identifizierung',
			titleLegiSkip: 'Unterschrift',
			dataRedirectionWebId: 'Mit der Datenweiterleitung an WebID Solutions GmbH erkläre ich mich einverstanden.',
			termsAndConditionsWebIdLabel1: 'Ich stimme den ',
			termsAndConditionsWebIdLinkText1: 'AGB',
			termsAndConditionsWebIdLabel2: ' und ',
			termsAndConditionsWebIdLinkText2: 'Datenschutzbestimmungen',
			termsAndConditionsWebIdLabel3: ' der WebID Solutions GmbH zu.',
			submitExtension1: 'oder',
			submitExtension2: 'POSTIDENT',
			submitExtension3: 'benutzen',
			kontoIdent: {
				title: 'Konto-Ident',
				subtitle: 'Identifizieren mit einer Überweisung von 1 Cent',
				list:
					'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>',
				toggleButton: 'Wie funktioniert es',
				toggleText:
					'<p class="text text--primary text--size--m mb--5"><strong>Identifizieren</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Fotos von Ausweis und Gesicht machen</li><li>Log-in ins Online-Banking, Überweisung von 1 Cent bestätigen</li><li>TAN-Eingabe</li></ol></div><p class="text text--primary text--size--m mb--5"><strong>Digital unterschreiben:</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Vertrag sichten</li><li>Per TAN-Eingabe unterschreiben</li></ol></div>'
			},
			videoIdent: {
				title: 'Video-Ident',
				subtitle: 'Videogespräch mit einem WebID-Agenten',
				list:
					'<li>Kurze Wartezeit</li><li>Von 7:00 bis 22:00 Uhr von überall möglich</li><li>Sicher und SSL verschlüsselt</li>',
				toggleButton: 'Wie funktioniert es',
				toggleText:
					'<p class="text text--primary text--size--m mb--5"><strong>Identifizieren</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Ausweis bereit halten</li><li>Videocall mit WebID-Agenten durchführen</li><li>TAN-Eingabe</li></ol></div><p class="text text--primary text--size--m mb--5"><strong>Digital unterschreiben:</strong></p><div class="text--primary text--size--m list"><ol class="mb--15"><li>Vertrag sichten</li><li>Per TAN-Eingabe unterschreiben</li></ol></div>'
			},
			digitalIdent: {
				title: 'Digitale Unterschrift',
				subtitle:
					'Personalausweis abfotografieren, Vertrag sichten und per TAN-Eingabe unterschreiben',
				list:
					'<li>Ohne Wartezeit</li><li>Jederzeit, egal ob zu Hause oder unterwegs</li><li>Sicher und SSL verschlüsselt</li>'
			},
			infoModal: {
				title: 'Seien Sie wachsam!',
				description: 'Werden Sie kein Opfer von Kriminellen. Santander hat keine Kooperationen mit Marktforschungsinstituten und führt keine Tests für Apps, Konten oder dem Online Banking MySantander durch. Sollten Sie mit dem Versprechen, Geld zu verdienen, zur Kontoeröffnung beauftragt worden sein, wenden Sie sich bitte an unser Service Team unter <a href="tel:+4921619060111" class="link primary">02161 - 9060 111</a>.',
				button: 'Verstanden'
			}
		},
		postIdent: {
			title: 'So geht es für Sie weiter',
			subtitle:
				'Bitte laden Sie das Formular herunter und führen Sie die Legitimationsprüfung in einer Postfiliale durch. Beachten Sie dabei, dass dieser Prozess einige Tage in Anspruch nehmen kann. Sie können sich immer noch für das schnellere und sichere Video-Ident Verfahren entscheiden.',
			submit: 'Zurück zu Video-Ident',
			link: {
				coupon: 'PostIdent Coupon herunterladen'
			}
		},
		digitalPostident: {
			title: 'Geschafft! Gehen Sie nun zur Identifikation in die Postfiliale',
			subtitle:
				'Identifizieren Sie Sich innerhalb von 10 Tagen in  einer Filiale der Deutschen Post.',
			step1: {
				text: 'QR Code',
				text2: 'Sie erhalten eine E-Mail mit einem QR-Code.'
			},
			step2: {
				text: 'Postfiliale',
				text2:
					'Gehen Sie mit Ihrem Ausweisdokument zur Postfiliale.'
			},
			step3: {
				text: 'Kontoeröffnung',
				text2:
					'Wir eröffnen Ihr Konto.'
			}
		},
		digitalPostidentConfirmation: {
			title: 'Nur noch identifizieren',
			subtitle:
				'Mit dem Post-Ident Verfahren können Sie Ihre Identität in jeder Filiale unseres Partners der Deutschen Post AG bestätigen.',
			checkbox:
				'Mit der Datenweiterleitung an die Deutsche Post AG erkläre ich mich einverstanden.'
		},
		uploadDocuments: {
			title:
				'Erforderliche Dokumente hochladen',
			subtitle:
				'Bitte benutzen Sie Dateien im Format PDF, JPG, oder PNG mit einer Maximalgröße von 10 MB pro Datei.',
			submitDisabledTooltip:
				'Um weiter zu machen, müssen Sie alle Dokumente hochladen'
		},
		uploadDocumentsLater: {
			title:
				'Wir haben Ihnen eine E-Mail geschickt. So können Sie jederzeit zurückkehren.',
			subtitle:
				'Wir können Ihren Antrag erst dann bearbeiten, wenn Sie alle benötigten Dokumente vollständig hochgeladen haben.'
		},
		addressSelection: {
			title: 'Welche Adresse ist korrekt?',
			subtitle:
				'Die von Ihnen angegebene Adresse stimmt nicht mit der Adresse aus Ihrem digitalen PostIdent Verfahren überein. Welche Adresse ist aktueller?',
			items: {
				webIdAddress: {
					title: 'Adresse in Ihrem Ausweis',
					subtitle: 'Bereits bestätigt. Direkte Kreditbeantragung.'
				},
				originalAddress: {
					title: 'Angegebene Adresse',
					subtitle: 'Erfordert einen weiteren Nachweis.'
				}
			}
		}
	}
};
