import React, { useState } from 'react';
import C from 'constants/Constants';
import { m } from 'utils/messages/messages';
import { useUtils } from 'utils/utils';
import { usePageUtils } from 'utils/page/page';
import { useTrackingUtils } from 'utils/tracking/tracking';
import { useValidationUtils } from 'utils/validation/validation';
import { useFlowUtils } from 'utils/flow/flow';

import Form from 'components/Molecules/Form/Form';
import { Link, Text, Title } from 'components/Atoms/Text';
import Autofill from 'components/Dev/Autofill/Autofill';

import { useDispatch, useSelector } from 'react-redux';
import { setBirthdate } from 'store/Birthdate/actions';
import SvgShareLink from 'components/Atoms/SVG/Icons/SvgShareLink';
import DateInput from 'components/Atoms/Form/DateInput/DateInput';
import moment from 'moment';

const Birthdate = () => {
	const { getCurrentFlow } = useUtils();
	const { goToPage } = usePageUtils();
	const { continueValid, continueInvalid } = useTrackingUtils();
	const { isEmpty, hasEmptyFieldsError, focusFirstErrorInput, isBirthdateValid } = useValidationUtils();
	const { gmkHyundaiMsgPrefix } = useFlowUtils();

	const currentFlow = getCurrentFlow();

	const dispatch = useDispatch();
	const storeBirthdate = useSelector((state => state.birthdate.birthdate));

	const [birthdateError, setBirthdateError] = useState(null);

	const clearErrors = () => {
		setBirthdateError(null);
	};

	const validateFields = async () => {
		let hasEmptyFields = false;
		let isValidBirthdate = true;

		if (isEmpty(storeBirthdate)) {
			setBirthdateError(m('validation.error.required', 'fields'));
			hasEmptyFields = true;
		} else {
			isValidBirthdate = isBirthdateValid(storeBirthdate, setBirthdateError);
		}
		await hasEmptyFieldsError(hasEmptyFields);

		const isValid = !hasEmptyFields && isValidBirthdate;

		if (!isValid) {
			focusFirstErrorInput();
		}

		return isValid;
	};

	const onSubmit = async () => {
		const isValid = await validateFields();
		if (isValid) {
			onValid();
		} else {
			onInvalid();
		}
	};

	const onValid = () => {
		continueValid();
		goToPage(currentFlow + C.ROUTES.EMAIL);
	};

	const onInvalid = () => {
		continueInvalid();
	};

	const onPrevButton = () => {
		if (currentFlow === C.FRONTEND.SPARBRIEF) {
			goToPage(currentFlow + C.ROUTES.NAME);
		} else {
			goToPage(currentFlow + C.ROUTES.INDEX);
		}
	};

	const autofill = () => {
		dispatch(setBirthdate('1990-01-31'));
	};

	return (
		<>
			<Autofill autofill={autofill} />
			<Title mb>{m('pages.birthdate.title', 'global')}</Title>
			<Text size="l" mb>{m(`${gmkHyundaiMsgPrefix}birthdate.subtitle`, currentFlow)}</Text>
			<Form
				onSubmit={onSubmit}
				clearErrors={clearErrors}
				submitTabindex={4}
				onPrevButton={onPrevButton}
			>
				<DateInput
					value={storeBirthdate}
					setValue={(value) => { dispatch(setBirthdate(value)); }}
					label={m('birthdate.label', 'fields')}
					max={moment().format('YYYY-MM-DD')}
					min={moment('1900').format('YYYY-MM-DD')}
					hasError={!!birthdateError}
					message={birthdateError}
					tabindex={1}
					testId="birthdate"
				/>
				<Text>
					{m('acceptPrivacyPolicies.description1', 'fields')}
					<Link
						href="https://www.santander.de/content/pdf/datenschutzhinweise/datenschutzhinweise-kundeninformation.pdf"
						target="_blank"
						tabindex={2}
					>
						{m('acceptPrivacyPolicies.linkText1', 'fields')}
						<SvgShareLink />
					</Link>
					{m('acceptPrivacyPolicies.description2', 'fields')}
					<Link
						href="https://www.santander.de/content/pdf/datenschutzhinweise/datenschutzhinweise-kundeninformation.pdf"
						target="_blank"
						tabindex={3}
					>
						{m('acceptPrivacyPolicies.linkText2', 'fields')}
						<SvgShareLink />
					</Link>
					{m('acceptPrivacyPolicies.description3', 'fields')}
				</Text>
			</Form>
		</>
	);
};
export default Birthdate;
